/// <reference path="./index.d.ts" />
//import { providers, ethers } from "ethers";
import helloHedera from "./bin/contracts/HelloHedera.json";
import NFTCreator from "./bin/contracts/NFTCreator.json";
import { FileCreateTransaction, AccountCreateTransaction, PrivateKey, Hbar, TransferTransaction, Client, AccountBalanceQuery, ContractCreateTransaction, ContractFunctionParameters, ContractExecuteTransaction, ContractCallQuery, ContractCreateFlow, AccountId, TokenNftInfoQuery, TokenId, NftId, PrngTransaction, FileAppendTransaction, FileContentsQuery, Timestamp, FileUpdateTransaction, HbarUnit, } from "@hashgraph/sdk";
import { hethers } from "@hashgraph/hethers";
import moment from "moment";
//import mapboxgl from "mapbox-gl";
import L from "leaflet";
import "leaflet-easybutton";
import "leaflet-sidebar-v2";
import "leaflet.locatecontrol";
/*
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import Map from 'ol/Map';
import View from 'ol/View';
import * as proj from 'ol/proj';

 // Set up the OSM layer
var myTileServer = new layer.Tile({
  source: new source.OSM({
    crossOrigin: null,
    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  })
});

// Create the map
var map = new Map({
  layers: [ myTileServer ],
  target: 'map',
  view: new View({
    center: proj.transform([10, 45], 'EPSG:4326', 'EPSG:3857'),
    zoom: 4
  })
})
*/
//import MetaMaskOnboarding from '@metamask/onboarding';
//const onboarding = new MetaMaskOnboarding();
//alert(MetaMaskOnboarding.isMetaMaskInstalled());
//import WalletConnectProvider from "@walletconnect/web3-provider";
const sendEIP1559Button = document.getElementById("B1");
const RequestPermissions = document.getElementById("B2");
//RequestPermissions.disabled = true;
const RequestAccounts = document.getElementById("B3");
//const WalletConnect = document.getElementById("B4") as HTMLButtonElement;
//const Hedera_to_Metamask = document.getElementById("B5") as HTMLButtonElement;
let map;
function init_map() {
    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
    };
    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
    function success(pos) {
        var crd = pos.coords;
        var radius = crd.accuracy;
        var lng = crd.longitude;
        var lat = crd.latitude;
        //alert([crd.longitude, crd.latitude]);
        //const img = document.getElementById("mapB1") as HTMLImageElement;
        map = L.map("map").setView([lat, lng], 16);
        // add the OpenStreetMap tiles
        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
        L.easyButton('<i class="fa-solid fa-house"></i>', function () {
            map.setView([lat, lng], 16);
        }).addTo(map);
        // show the scale bar on the lower left corner
        L.control.scale({ imperial: true, metric: true }).addTo(map);
        // show a marker on the map
        L.marker([lat, lng]).addTo(map).bindPopup("Ψ Here").openPopup();
        L.circle([lat, lng], radius).addTo(map);
        init_sidebar();
        L.control
            .locate({
            locateOptions: {
                enableHighAccuracy: true,
            },
            drawCircle: false,
            keepCurrentZoomLevel: true,
        })
            .addTo(map);
    }
}
function init_sidebar() {
    // create the sidebar instance and add it to the map
    var sidebar = L.control
        .sidebar({ container: "sidebar" })
        .addTo(map)
        .open("home")
        .close();
    var userid = 0;
    function addUser() {
        sidebar.addPanel({
            id: "user" + userid++,
            tab: '<i class="fa-solid fa-user"></i>',
            title: "User Profile " + userid,
            pane: "<p>user ipsum dolor sit amet</p>",
        });
    }
    // add panels dynamically to the sidebar
    sidebar
        .addPanel({
        id: "js-api",
        tab: '<i class="fa-solid fa-gear"></i>',
        title: "JS API",
        pane: '<p>The Javascript API allows to dynamically create or modify the panel state.<p/>\
      <p><button id="b1">enable mails panel</button>\
      <button id="b2">disable mails panel</button></p>\
      <p><button id="b3">add user</button></b>',
    })
        // add a tab with a click callback, initially disabled
        .addPanel({
        id: "mail",
        tab: '<i class="fa-regular fa-envelope"></i>',
        title: "Messages",
        button: function () {
            alert("opened via JS callback");
        },
        disabled: true,
    });
    // be notified when a panel is opened
    sidebar.on("content", function (ev) {
        // @ts-ignore
        switch (ev.id) {
            case "autopan":
                // @ts-ignore
                sidebar.options.autopan = true;
                break;
            default:
                // @ts-ignore
                sidebar.options.autopan = false;
        }
    });
    const but1 = document.getElementById("b1");
    const but2 = document.getElementById("b2");
    const but3 = document.getElementById("b3");
    but1.onclick = async () => {
        sidebar.enablePanel("mail");
    };
    but2.onclick = async () => {
        sidebar.disablePanel("mail");
    };
    but3.onclick = async () => {
        addUser();
    };
}
init_map();
let ethereum;
let currentAccount;
let provider;
RequestAccounts.onclick = async () => {
    const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
    });
    if (accounts) {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x89" }],
            });
        }
        catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: "0x89",
                                chainName: "Polygon",
                                rpcUrls: ["https://polygon-rpc.com/"],
                            },
                        ],
                    });
                }
                catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
        ethereum = window.ethereum;
        //provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        currentAccount = accounts[0];
        //RequestPermissions.disabled = false;
        alert(currentAccount);
    }
};
/*
//Previewnet
RequestAccounts.onclick = async () => {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  if (accounts) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x129" }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                blockExplorerUrls: [
                  "https://hashscan.io/#/previewnet/dashboard",
                ],
                nativeCurrency: {
                  name: "HBAR",
                  symbol: "HBAR",
                  decimals: 18,
                },

                chainId: "0x129",
                chainName: "Hedera",
                rpcUrls: ["https://previewnet.hashio.io/api"],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }

    ethereum = window.ethereum;

    //provider = new ethers.providers.Web3Provider(window.ethereum, "any");

    currentAccount = accounts[0];
    RequestPermissions.disabled = false;
    alert(currentAccount);
  }
};
*/
//Testnet
RequestAccounts.onclick = async () => {
    const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
    });
    if (accounts) {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x128" }],
            });
        }
        catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                blockExplorerUrls: [
                                    "https://hashscan.io/#/testnet/dashboard",
                                ],
                                nativeCurrency: {
                                    name: "HBAR",
                                    symbol: "HBAR",
                                    decimals: 18,
                                },
                                chainId: "0x128",
                                chainName: "Hedera Testnet",
                                rpcUrls: ["https://testnet.hashio.io/api"],
                            },
                        ],
                    });
                }
                catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
        ethereum = window.ethereum;
        //provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        currentAccount = accounts[0];
        RequestPermissions.disabled = false;
        alert(currentAccount);
    }
};
/*
WalletConnect.onclick = async () => {

  //  Create WalletConnect Provider
  const provider = new WalletConnectProvider({
    rpc: {
      137: "https://matic-mainnet.chainstacklabs.com",
    },
    infuraId: JSON.parse(JSON.stringify(await get_file("infura"))).ID,
  });

  // Subscribe to accounts change
  
  provider.on("accountsChanged", (accounts: string[]) => {
    currentAccount = accounts[0];
    console.log(accounts);
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId: number) => {
    console.log(chainId);
  });

  // Subscribe to session disconnection
  provider.on("disconnect", (code: number, reason: string) => {
    console.log(code, reason);
  });

  //  Enable session (triggers QR Code modal)
  await provider.enable();
  ethereum = provider;

}
*/
async function get_file(file) {
    return fetch(file)
        .then((x) => x.json())
        .then((y) => {
        return y;
    });
}
sendEIP1559Button.onclick = async () => {
    const result = await ethereum
        .request({
        method: "eth_sendTransaction",
        params: [
            {
                from: currentAccount,
                to: currentAccount,
                value: e2w(0.01),
                // gasLimit: "0x09184e72a000",
                // maxFeePerGas: "0x2540be400",
                // maxPriorityFeePerGas: "0x3b9aca00",
            },
        ],
    })
        .catch((err) => {
        if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Rejected");
        }
        else {
            console.error(err);
        }
    });
    console.log(result);
};
/*
RequestPermissions.onclick = async () => {
  ethereum
    .request({
      method: "wallet_requestPermissions",
      params: [{ eth_accounts: {} }],
    })
    .then((permissions: any[]) => {
      const accountsPermission = permissions.find(
        (permission: { parentCapability: string }) =>
          permission.parentCapability === "eth_accounts"
      );
      if (accountsPermission) {
        alert("eth_accounts permission successfully requested!");
      }
    })
    .catch((error: { code: number }) => {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        alert("Permissions needed to continue.");
      } else {
        console.error(error);
      }
    });
};
*/
function e2w(val) {
    var v = "0x" + (val * 10 ** 18).toString(16);
    //console.log(v);
    return v;
}
const BRNG = document.getElementById("BUTTON1");
BRNG.style.visibility = "visible";
const snd = new Audio("./sounds/beep-07a.wav");
const sndPause = new Audio("./sounds/beep-02.wav");
var Synchronicity_data = {
    RandomNumber: [],
    TimeStamp: [],
    Latitude: [],
    Longitude: [],
    Accuracy: [],
    Altitude: [],
    AltitudeAccuracy: [],
    Heading: [],
    Speed: [],
};
$.ajax({
    dataType: "json",
    url: "./env.php",
    success: async function (data) {
        /*
        async function check_accounts() {
          //console.log('account', provider.accounts[0]);
          var found = false;
          if(currentAccount){
            const arr = (await get_file("accounts")).Accounts;
          if (
            JSON.stringify(arr)
              .toUpperCase()
              .includes(JSON.stringify(currentAccount).toUpperCase())
          ) {
            found = true;
            console.log("found:", found);
          }
        }
          return found;
        }
    
        if (!(await check_accounts())) {
          return;
        }
    */
        function e2w(val) {
            var v = "0x" + (val * 10 ** 18).toString(16);
            //console.log(v);
            return v;
        }
        //////////////////
        const record = document.getElementById("record");
        const stop = document.getElementById("stop");
        const pause = document.getElementById("pause");
        const soundClips = document.querySelector(".sound-clips");
        const canvas = document.querySelector(".visualizer");
        const mainSection = document.querySelector(".main-controls");
        let mediaRecorder;
        // disable stop button while not recording
        // visualiser setup - create web audio api context and canvas
        let audioCtx;
        const canvasCtx = canvas.getContext("2d");
        //main block for doing the audio recording
        const constraints = {
            audio: true,
        };
        var chunks = [];
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
            mediaRecorder = new MediaRecorder(stream);
            console.log("getUserMedia supported.");
            visualize(stream);
            record.onclick = () => {
                if (mediaRecorder.state === "recording") {
                    return; // Do nothing if the event was already processed
                }
                if (mediaRecorder.state === "paused") {
                    mediaRecorder.resume();
                    // resume recording
                }
                else {
                    mediaRecorder.start();
                }
                console.log(mediaRecorder.state);
                console.log("recorder started");
                //record.style.background = "red";
                //record.style.color = "black";
            };
            stop.onclick = () => {
                if (mediaRecorder.state === "inactive") {
                    return; // Do nothing if the event was already processed
                }
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
                console.log("recorder stopped");
                record.style.background = "";
                record.style.color = "";
                pause.style.background = "";
                pause.style.color = "";
            };
            pause.onclick = () => {
                if (mediaRecorder.state === "inactive") {
                    return; // Do nothing if the event was already processed
                }
                if (mediaRecorder.state === "recording") {
                    mediaRecorder.pause();
                    console.log("recorder paused");
                    record.style.background = "yellow";
                    // recording paused
                }
                else if (mediaRecorder.state === "paused") {
                    mediaRecorder.resume();
                    console.log("recorder resumed");
                    record.style.background = "";
                    // resume recording
                }
                console.log(mediaRecorder.state);
            };
            mediaRecorder.onstop = () => {
                console.log("data available after MediaRecorder.stop() called.");
                const clipName = prompt("Enter a name for your sound clip", "My unnamed clip");
                const clipContainer = document.createElement("article");
                const clipLabel = document.createElement("p");
                const audio = document.createElement("audio");
                const deleteButton = document.createElement("button");
                deleteButton.className = "button2";
                clipContainer.classList.add("clip");
                audio.setAttribute("controls", "");
                audio.setAttribute("style", "height: 100px");
                deleteButton.textContent = "Delete";
                clipLabel.textContent = clipName;
                clipContainer.appendChild(audio);
                clipContainer.appendChild(clipLabel);
                clipContainer.appendChild(deleteButton);
                soundClips.appendChild(clipContainer);
                audio.controls = true;
                const blob = new Blob(chunks, {
                    type: "audio/ogg; codecs=opus",
                });
                chunks = [];
                const audioURL = URL.createObjectURL(blob);
                audio.src = audioURL;
                console.log("recorder stopped");
                deleteButton.onclick = () => {
                    while (clipContainer.hasChildNodes()) {
                        clipContainer.removeChild(clipContainer.firstChild);
                    }
                };
                clipLabel.onclick = function () {
                    const existingName = clipLabel.textContent;
                    const newClipName = prompt("Enter a new name for your sound clip?");
                    if (newClipName === null) {
                        clipLabel.textContent = existingName;
                    }
                    else {
                        clipLabel.textContent = newClipName;
                    }
                };
            };
            mediaRecorder.ondataavailable = (e) => {
                chunks.push(e.data);
            };
        })
            .catch((err) => {
            console.error(`The following error occurred: ${err}`);
        });
        function visualize(stream) {
            if (!audioCtx) {
                audioCtx = new AudioContext();
            }
            const source = audioCtx.createMediaStreamSource(stream);
            const analyser = audioCtx.createAnalyser();
            // analyser.fftSize = 2048;
            const bufferLength = 2048; //analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            source.connect(analyser);
            console.log("mediaRecorder.state:", mediaRecorder.state);
            draw();
            function draw() {
                canvas.width = mainSection.offsetWidth;
                //canvas.height = 300;
                const WIDTH = canvas.width;
                const HEIGHT = canvas.height;
                requestAnimationFrame(draw);
                analyser.getByteTimeDomainData(dataArray);
                canvasCtx.fillStyle = "rgb(200, 200, 200)";
                canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
                canvasCtx.lineWidth = 2;
                canvasCtx.strokeStyle = "rgb(0, 0, 0)";
                canvasCtx.beginPath();
                let sliceWidth = (WIDTH * 1.0) / bufferLength;
                let x = 0;
                for (let i = 0; i < bufferLength; i++) {
                    let v = dataArray[i] / 128.0;
                    let y = (v * HEIGHT) / 2;
                    if (i === 0) {
                        canvasCtx.moveTo(x, y);
                    }
                    else {
                        canvasCtx.lineTo(x, y);
                    }
                    x += sliceWidth;
                }
                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                canvasCtx.stroke();
            }
        }
        //////////////////
        let OPERATOR_ID;
        let OPERATOR_KEY;
        let fileKeyj;
        let client = new Client();
        const select_net = "t";
        if (select_net === "t") {
            OPERATOR_ID = data.testnet.OPERATOR_ID;
            OPERATOR_KEY = data.testnet.OPERATOR_KEY;
            fileKeyj = data.testnet.fileKey;
            client = Client.forTestnet().setOperator(OPERATOR_ID, OPERATOR_KEY);
        }
        else if (select_net === "p") {
            OPERATOR_ID = data.previewnet.OPERATOR_ID;
            OPERATOR_KEY = data.previewnet.OPERATOR_KEY;
            fileKeyj = data.previewnet.fileKey;
            client = Client.forPreviewnet().setOperator(OPERATOR_ID, OPERATOR_KEY);
        }
        /*
        Hedera_to_Metamask.onclick = async () => {
          // generate an ECDSA key-pair
          const newPrivateKey = PrivateKey.generateECDSA();
          console.log(
            `The raw private key (use this for JSON RPC wallet import): ${newPrivateKey.toStringRaw()}`
          );
    
          const newPublicKey = newPrivateKey.publicKey;
    
          // account publickey alias
          const aliasAccountId = newPublicKey.toAccountId(0, 0);
          console.log(`The alias account id: ${aliasAccountId}`);
    
          const operatorAccountId = OPERATOR_ID;
          const operatorPrivateKey = PrivateKey.fromString(OPERATOR_KEY);
    
          // Hbar transfers will auto-create a Hedera Account
          // for long-form account Ids that do not have accounts yet
          const tokenTransferTxn = async (
            senderAccountId: string,
            receiverAccountId: any,
            hbarAmount: string | number
          ) => {
            const transferToAliasTx = new TransferTransaction()
              .addHbarTransfer(senderAccountId, new Hbar(-hbarAmount))
              .addHbarTransfer(receiverAccountId, new Hbar(hbarAmount))
              .freezeWith(client);
    
            const signedTx = await transferToAliasTx.sign(operatorPrivateKey);
            const txResponse = await signedTx.execute(client);
            await txResponse.getReceipt(client);
          };
    
          const logAccountInfo = async (accountId: any) => {
            const info = await new AccountInfoQuery()
              .setAccountId(accountId)
              .execute(client);
    
            console.log(`The normal account ID: ${info.accountId}`);
            console.log(`Account Balance: ${info.balance}`);
          };
    
          const main = async () => {
            await tokenTransferTxn(operatorAccountId, aliasAccountId, 10);
            await logAccountInfo(aliasAccountId);
          };
          
          main();
          //The raw private key (use this for JSON RPC wallet import):
          //2d48718a96be0e61845b2dc55dd0477e8d0d78eb07ef0bfa3f1c8a2d950751a4
          //e4b3104c2dc312eda4b7e7501ab8515b1cac4bb39ae322f4bbd384e774c42849
    
          //The alias account id:
          //0.0.302d300706052b8104000a032200026209e51912d44a6a02879e46a5554ba97c2aac68cc33ec7bb7aaaf6b55bfc606
          
          //0.0.302d300706052b8104000a03220002c6713ff8e919c413c3b0d25a8d6fd09c82dc759637c8a0a35337f6007c4fc197
          //The normal account ID: 0.0.106039
          //The raw private key (use this for JSON RPC wallet import):
          //f0184fddf434a7070e81746377e7c918285e1b7c72d080eedd49f6e02c87192d
        };
    
        const logAccountInfo = async (accountId: any) => {
          const info = await new AccountInfoQuery()
            .setAccountId(accountId)
            .execute(client);
    
          console.log(`Account ID: ${info.accountId}`);
          console.log(`Account Balance: ${info.balance}`);
        };
    
        const tokenTransferTxn = async (
          senderAccountId: string,
          receiverAccountId: any,
          hbarAmount: string | number
        ) => {
          const operatorPrivateKey = PrivateKey.fromString(OPERATOR_KEY);
          const transferToAliasTx = new TransferTransaction()
            .addHbarTransfer(senderAccountId, new Hbar(-hbarAmount))
            .addHbarTransfer(receiverAccountId, new Hbar(hbarAmount))
            .freezeWith(client);
    
          const signedTx = await transferToAliasTx.sign(operatorPrivateKey);
          const txResponse = await signedTx.execute(client);
          await txResponse.getReceipt(client);
        };
        */
        //await tokenTransferTxn('0.0.2307', '0.0.106039', 0.1);
        //await logAccountInfo('0.0.2307');// 0.0.106039
        //await logAccountInfo('0.0.106039');// 0.0.106039
        async function genED25519() {
            const privateKey = await PrivateKey.generateED25519Async();
            const publicKey = privateKey.publicKey;
            console.log("private:", privateKey.toStringRaw());
            console.log("public: ", publicKey.toStringRaw());
            console.log("private = " + privateKey);
            console.log("public  = " + publicKey);
        }
        async function generateECDSA() {
            const fileKey = PrivateKey.generateECDSA();
            const filePublicKey = fileKey.publicKey;
            console.log("fileKey:      ", fileKey.toStringRaw());
            console.log("filePublicKey:", filePublicKey.toStringRaw());
        }
        function timestamp_year(nyears) {
            const minute = 60;
            const hour = minute * 60;
            const day = hour * 24;
            const year = day * 365;
            const timestamp = new Timestamp(nyears * year, 0);
            console.log("timestamp:", timestamp.toString());
            return timestamp;
        }
        async function fileAPI() {
            //Create the transaction
            // Note that total size for a given transaction is limited to 6KiB (as of March 2020)
            //Create a file
            //console.log(fileKeyj);
            const fileKey = PrivateKey.fromString(fileKeyj);
            const filePublicKey = fileKey.publicKey;
            const transaction = new FileCreateTransaction()
                .setKeys([filePublicKey]) //A different key then the client operator key
                .setContents("the file contents")
                .setMaxTransactionFee(new Hbar(2))
                .freezeWith(client);
            //Sign with the file private key
            const signTx = await transaction.sign(fileKey);
            //Sign with the client operator private key and submit to a Hedera network
            const submitTx = await signTx.execute(client);
            //Request the receipt
            const receipt = await submitTx.getReceipt(client);
            //Get the file ID
            const newFileId = receipt.fileId;
            console.log("The new file ID is: " + newFileId);
            //Create the transaction
            //Append to a file
            const transactiona = new FileAppendTransaction()
                // @ts-ignore
                .setFileId(newFileId)
                .setContents("The appended contents")
                .setMaxTransactionFee(new Hbar(2))
                .freezeWith(client);
            //Sign with the file private key
            const signTxa = await transactiona.sign(fileKey);
            //Sign with the client operator key and submit to a Hedera network
            const txResponsea = await signTxa.execute(client);
            //Request the receipt
            const receipta = await txResponsea.getReceipt(client);
            //Get the transaction consensus status
            const transactionStatusa = receipta.status;
            console.log("The transaction consensus status is " + transactionStatusa);
            //Create the query
            const query = new FileContentsQuery()
                // @ts-ignore
                .setFileId(newFileId);
            //Sign with client operator private key and submit the query to a Hedera network
            const contents = await query.execute(client);
            const aa = new TextDecoder().decode(contents);
            console.log("File contents:", aa);
            //Create the transaction
            const transaction3 = new FileUpdateTransaction()
                .setFileId(newFileId)
                .setContents("The new contents")
                .setMaxTransactionFee(new Hbar(2))
                //.setExpirationTime(timestamp_year(1))
                .freezeWith(client);
            //Sign with the file private key
            const signTx3 = await transaction3.sign(fileKey);
            //Sign with the client operator private key and submit to a Hedera network
            const submitTx3 = await signTx3.execute(client);
            //Request the receipt
            const receipt3 = await submitTx3.getReceipt(client);
            //Get the transaction consensus status
            const transactionStatus3 = receipt3.status;
            console.log("The transaction consensus status " + transactionStatus3.toString());
        }
        function msToTime(timestamp) {
            var time = Math.round(new Date(timestamp).getMilliseconds() / 10);
            var time2 = new Date(timestamp);
            const t = time2.getHours().toLocaleString().padStart(2, "0") +
                ":" +
                time2.getMinutes().toLocaleString().padStart(2, "0") +
                ":" +
                time2.getSeconds().toLocaleString().padStart(2, "0") +
                "." +
                time.toLocaleString().padStart(2, "0");
            return t;
        }
        async function randomNumber(a, b) {
            //const data = await fetch("./bin/contracts/Prng.json");
            //const bc = await data.json();
            //const bytecode = bc.bytecode;
            /*
            const bytecode = RNG.bytecode;
      
            //Create a file on Hedera and store the hex-encoded bytecode
            const fileCreateTx = new FileCreateTransaction()
              //Set the bytecode of the contract
              .setContents(bytecode);
      
            //Submit the file to the Hedera test network signing with the transaction fee payer key specified with the client
            const submitTx = await fileCreateTx.execute(client);
      
            //Get the receipt of the file create transaction
            const fileReceipt = await submitTx.getReceipt(client);
      
            //Get the file ID from the receipt
            const bytecodeFileId: any = fileReceipt.fileId;
      
            //Log the file ID
            console.log("The smart contract byte code file ID is " + bytecodeFileId);
      
            // Instantiate the contract instance
            const contractTx = new ContractCreateTransaction()
              //Set the file ID of the Hedera file storing the bytecode
              .setBytecodeFileId(bytecodeFileId)
              //Set the gas to instantiate the contract
              .setGas(100000);
      
            //Submit the transaction to the Hedera test network
            const contractResponse = await contractTx.execute(client);
      
            //Get the receipt of the file create transaction
            const contractReceipt = await contractResponse.getReceipt(client);
      
            //Get the smart contract ID
            const newContractId: any = contractReceipt.contractId;
      
            //Log the smart contract ID
            console.log("The smart contract ID is " + newContractId);
      
      
           */
            const newContractId = "0.0.7457826"; //"0.0.63695"previewnet //"";testnet
            // Calls a function of the smart contract
            const contractQuery = new ContractCallQuery()
                //Set the gas for the query
                .setGas(100000)
                //Set the contract ID to return the request for
                .setContractId(newContractId)
                //Set the contract function to call
                .setFunction("RNG2", new ContractFunctionParameters().addUint32(a).addUint32(b))
                //.setFunction("RNG")
                //Set the query payment for the node returning the request
                //This value must cover the cost of the request otherwise will fail
                .setQueryPayment(Hbar.from(500, HbarUnit.Millibar)); //$0.001
            //Submit to a Hedera network
            const getMessage = await contractQuery.execute(client);
            const ts = moment(contractQuery._timestamp).local(true).toISOString(true);
            const header = document.getElementById("timestamp");
            header.innerText = "Timestamp:\n" + ts;
            Synchronicity_data.TimeStamp.push(ts);
            // Get a string from the result at index 0
            const message = getMessage.getUint32(0);
            //const message = getMessage.getBytes32(0);
            //Log the message
            //console.log("The contract message: " + message);
            //console.log("Random number between 9990 and 10010 : " + message);
            /// console.log("Random number between 1 and 6 : " + message);
            //prompt("Random number between 1 and 6 : ", message);
            Synchronicity_data.RandomNumber.push(message.toString());
            return message;
        }
        async function randomnumberAPI() {
            //Create the transaction with range set
            const transaction = await new PrngTransaction()
                //Set the range
                .setRange(10)
                .execute(client);
            //Get the record
            const transactionRecord = await transaction.getRecord(client);
            //Get the number
            const prngNumber = transactionRecord.prngNumber;
            console.log("Random Number:", prngNumber);
        }
        async function test() {
            // To validate the network is up and the client can connect to it you can call
            // the `Client.ping()` method. If the network is up nothing will happen, if
            // the network is down, or the client failed to connect to the network for some
            // other reason then this call would throw an error.
            await client.ping(OPERATOR_ID);
            const aa = new AccountBalanceQuery().setAccountId(OPERATOR_ID);
            console.log("current account balance:", await aa.execute(client));
            // Create a transaction to transfer 100 hbars
            const transaction = new TransferTransaction()
                .addHbarTransfer(OPERATOR_ID, new Hbar(-100))
                .addHbarTransfer(OPERATOR_ID, new Hbar(100));
            //Submit the transaction to a Hedera network
            const txResponse = await transaction.execute(client);
            //Request the receipt of the transaction
            const receipt = await txResponse.getReceipt(client);
            //Get the transaction consensus status
            const transactionStatus = receipt.status;
            console.log("The transaction consensus status is " + transactionStatus.toString());
            console.log("current account balance:", await aa.execute(client));
        }
        async function test2() {
            //Create new keys
            const newAccountPrivateKey = PrivateKey.generateED25519();
            const newAccountPublicKey = newAccountPrivateKey.publicKey;
            await client.ping(OPERATOR_ID);
            //Create a new account with 1,000 tinybar starting balance
            const newAccount = await new AccountCreateTransaction()
                .setKey(newAccountPublicKey)
                .setInitialBalance(Hbar.fromTinybars(1000))
                .execute(client);
            // Get the new account ID
            const getReceipt = await newAccount.getReceipt(client);
            const newAccountId = getReceipt.accountId;
            //Log the account ID
            console.log("The new account ID is: " + newAccountId);
            //Verify the account balance
            const accountBalance = await new AccountBalanceQuery()
                // @ts-ignore
                .setAccountId(newAccountId)
                .execute(client);
            console.log("The new account balance is: " +
                accountBalance.hbars.toTinybars() +
                " tinybar.");
        }
        //helloHedera smart contract
        async function test3() {
            // @ts-ignore
            const bytecode = helloHedera.data.bytecode.object;
            //Create a file on Hedera and store the hex-encoded bytecode
            const fileCreateTx = new FileCreateTransaction()
                //Set the bytecode of the contract
                .setContents(bytecode);
            //Submit the file to the Hedera test network signing with the transaction fee payer key specified with the client
            const submitTx = await fileCreateTx.execute(client);
            //Get the receipt of the file create transaction
            const fileReceipt = await submitTx.getReceipt(client);
            //Get the file ID from the receipt
            const bytecodeFileId = fileReceipt.fileId;
            //Log the file ID
            console.log("The smart contract byte code file ID is " + bytecodeFileId);
            // Instantiate the contract instance
            const contractTx = new ContractCreateTransaction()
                //Set the file ID of the Hedera file storing the bytecode
                // @ts-ignore
                .setBytecodeFileId(bytecodeFileId)
                //Set the gas to instantiate the contract
                .setGas(100000)
                //Provide the constructor parameters for the contract
                .setConstructorParameters(new ContractFunctionParameters().addString("Hello from Hedera!"));
            //Submit the transaction to the Hedera test network
            const contractResponse = await contractTx.execute(client);
            //Get the receipt of the file create transaction
            const contractReceipt = await contractResponse.getReceipt(client);
            //Get the smart contract ID
            const newContractId = contractReceipt.contractId;
            //Log the smart contract ID
            console.log("The smart contract ID is " + newContractId);
            // Calls a function of the smart contract
            const contractQuery = new ContractCallQuery()
                //Set the gas for the query
                .setGas(100000)
                //Set the contract ID to return the request for
                // @ts-ignore
                .setContractId(newContractId)
                //Set the contract function to call
                .setFunction("get_message")
                //Set the query payment for the node returning the request
                //This value must cover the cost of the request otherwise will fail
                .setQueryPayment(new Hbar(2));
            //Submit to a Hedera network
            const getMessage = await contractQuery.execute(client);
            // Get a string from the result at index 0
            const message = getMessage.getString(0);
            //Log the message
            console.log("The contract message: " + message);
            //Create the transaction to update the contract message
            const contractExecTx = new ContractExecuteTransaction()
                //Set the ID of the contract
                .setContractId(newContractId)
                //Set the gas for the contract call
                .setGas(100000)
                //Set the contract function to call
                .setFunction("set_message", new ContractFunctionParameters().addString("Hello from Hedera again!"));
            //Submit the transaction to a Hedera network and store the response
            const submitExecTx = await contractExecTx.execute(client);
            //Get the receipt of the transaction
            const receipt2 = await submitExecTx.getReceipt(client);
            //Confirm the transaction was executed successfully
            console.log("The transaction status is " + receipt2.status.toString());
            //Query the contract for the contract message
            const contractCallQuery = new ContractCallQuery()
                //Set the ID of the contract to query
                // @ts-ignore
                .setContractId(newContractId)
                //Set the gas to execute the contract call
                .setGas(100000)
                //Set the contract function to call
                .setFunction("get_message")
                //Set the query payment for the node returning the request
                //This value must cover the cost of the request otherwise will fail
                .setQueryPayment(new Hbar(10));
            //Submit the transaction to a Hedera network
            const contractUpdateResult = await contractCallQuery.execute(client);
            //Get the updated message at index 0
            const message2 = contractUpdateResult.getString(0);
            //Log the updated message to the console
            console.log("The updated contract message: " + message2);
        }
        function hexToUtf8(s) {
            return decodeURIComponent(s
                .replace(/\s+/g, "") // remove spaces
                .replace(/[0-9a-f]{2}/g, "%$&") // add '%' before each 2 characters
            );
        }
        async function test4() {
            var begin = Date.now();
            const bytecode = NFTCreator.bytecode;
            const createContract = new ContractCreateFlow()
                .setGas(150000) // Increase if revert
                .setBytecode(bytecode); // Contract bytecode
            const createContractTx = await createContract.execute(client);
            const createContractRx = await createContractTx.getReceipt(client);
            const contractId = createContractRx.contractId;
            console.log(`Contract created with ID: ${contractId} \n`);
            // Create NFT from precompile
            const createToken = new ContractExecuteTransaction()
                .setContractId(contractId)
                .setGas(300000) // Increase if revert
                .setPayableAmount(20) // Increase if revert
                .setFunction("createNft", new ContractFunctionParameters()
                .addString("Fall Collection") // NFT name
                .addString("LEAF") // NFT symbol
                .addString("Just a memo") // NFT memo
                .addUint32(250) // NFT max supply
                .addUint32(7000000) // Expiration: Needs to be between 6999999 and 8000001
            );
            const createTokenTx = await createToken.execute(client);
            const createTokenRx = await createTokenTx.getRecord(client);
            const tokenIdSolidityAddr = createTokenRx.contractFunctionResult.getAddress(0);
            const tokenId = AccountId.fromSolidityAddress(tokenIdSolidityAddr);
            console.log(`Token created with ID: ${tokenId} \n`);
            // ipfs URI
            const metadata = "https://bafkreifl34iaazrb77e3xnmr3iapjozbkdqv7gwv5x43lxjxwzmbi3av2q.ipfs.w3s.link/";
            const utf8Encode = new TextEncoder();
            const byteArr = utf8Encode.encode(metadata);
            // Mint NFT
            const mintToken = new ContractExecuteTransaction()
                // @ts-ignore
                .setContractId(contractId)
                .setGas(1000000)
                .setFunction("mintNft", new ContractFunctionParameters()
                .addAddress(tokenIdSolidityAddr) // Token address
                .addBytesArray([byteArr]) // Metadata
            );
            const mintTokenTx = await mintToken.execute(client);
            const mintTokenRx = await mintTokenTx.getRecord(client);
            const serial = mintTokenRx.contractFunctionResult.getInt64(0);
            console.log(`Minted NFT with serial: ${serial} \n`);
            const NftID = new NftId(new TokenId(tokenId), serial.toNumber());
            // Returns the info for the specified NFT ID
            const nftInfos = await new TokenNftInfoQuery()
                .setNftId(NftID)
                .execute(client);
            // @ts-ignore
            console.log("NFT metadata:", hexToUtf8(JSON.parse(nftInfos).metadata));
            /////////////////////////////////////////////////////////
            const newAccountPrivateKey = PrivateKey.generateED25519();
            const newAccountPublicKey = newAccountPrivateKey.publicKey;
            await client.ping(OPERATOR_ID);
            //Create a new account with 1,000 tinybar starting balance
            const newAccount = await new AccountCreateTransaction()
                .setKey(newAccountPublicKey)
                .setInitialBalance(Hbar.fromTinybars(1000))
                .execute(client);
            // Get the new account ID
            const getReceipt = await newAccount.getReceipt(client);
            const newAccountId = getReceipt.accountId;
            //Log the account ID
            console.log("The new account ID is: " + newAccountId);
            const aliceKey = newAccountPrivateKey;
            const aliceId = newAccountId;
            /////////////////////////////////////////////////////////
            // Transfer NFT to Alice
            const transferToken = await new ContractExecuteTransaction()
                .setContractId(contractId)
                .setGas(1000000)
                .setFunction("transferNft", new ContractFunctionParameters()
                .addAddress(tokenIdSolidityAddr) // Token address
                // @ts-ignore
                .addAddress(aliceId.toSolidityAddress()) // Token receiver (Alice)
                .addInt64(serial)) // NFT serial number
                .freezeWith(client) // freezing using client
                .sign(aliceKey); // Sign transaction with Alice
            const transferTokenTx = await transferToken.execute(client);
            const transferTokenRx = await transferTokenTx.getReceipt(client);
            console.log(`Transfer status: ${transferTokenRx.status} \n`);
            var end = Date.now();
            var timeSpent = (end - begin) / 1000 + "secs";
            console.log("Duration:", timeSpent);
        }
        async function test5() {
            //console.clear();
            //const aliceId = AccountId.fromString(process.env.ALICE_ID);
            /////////////////////////////////////////////////////////
            const newAccountPrivateKey = PrivateKey.generateECDSA();
            const newAccountPublicKey = newAccountPrivateKey.publicKey;
            await client.ping(OPERATOR_ID);
            //Create a new account with 1,000 tinybar starting balance
            const newAccount = await new AccountCreateTransaction()
                .setKey(newAccountPublicKey)
                //.setInitialBalance(Hbar.fromTinybars(1000))
                .setInitialBalance(new Hbar(100))
                .execute(client);
            // Get the new account ID
            const getReceipt = await newAccount.getReceipt(client);
            const newAccountId = getReceipt.accountId;
            //Log the account ID
            console.log("The new account ID is: " + newAccountId);
            const aliceKey = newAccountPrivateKey;
            const aliceId = newAccountId;
            /////////////////////////////////////////////////////////
            /////////////////////////////////////////////////////////
            const newAccountPrivateKey2 = PrivateKey.generateECDSA();
            const newAccountPublicKey2 = newAccountPrivateKey2.publicKey;
            await client.ping(OPERATOR_ID);
            //Create a new account with 1,000 tinybar starting balance
            const newAccount2 = await new AccountCreateTransaction()
                .setKey(newAccountPublicKey2)
                .setInitialBalance(new Hbar(100))
                .execute(client);
            // Get the new account ID
            const getReceipt2 = await newAccount2.getReceipt(client);
            const newAccountId2 = getReceipt2.accountId;
            //Log the account ID
            console.log("The new account ID is: " + newAccountId2);
            const signerKey = newAccountPrivateKey2;
            const signerId = newAccountId2;
            /////////////////////////////////////////////////////////
            //const signerId = AccountId.fromString(OPERATOR_ID);
            //const signerKey = PrivateKey.fromString(OPERATOR_KEY); // TO WORK WITH HETHERS, IT MUST BE ECDSA KEY (FOR NOW)
            // @ts-ignore
            const walletAddress = hethers.utils.getAddressFromAccount(signerId);
            // @ts-ignore
            const aliceAddress = hethers.utils.getAddressFromAccount(aliceId);
            // =============================================================================
            // STEP 1 - INITIALIZE A PROVIDER AND WALLET
            console.log(`\n- STEP 1 ===================================`);
            const provider = hethers.providers.getDefaultProvider("testnet");
            //const provider = hethers.providers.getDefaultProvider("previewnet");
            const eoaAccount = {
                account: signerId,
                privateKey: `0x${signerKey.toStringRaw()}`, // Convert private key to short format using .toStringRaw()
            };
            // @ts-ignore
            const wallet = new hethers.Wallet(eoaAccount, provider);
            console.log(`\n- Alice's address: ${aliceAddress}`);
            console.log(`\n- Wallet address: ${wallet.address}`);
            console.log(`\n- Wallet public key: ${wallet.publicKey}`);
            // @ts-ignore
            const balance = await wallet.getBalance(walletAddress);
            console.log(`\n- Wallet address balance: ${hethers.utils.formatHbar(balance.toString())} hbar`);
            // =============================================================================
            // STEP 2 - DEPLOY THE CONTRACT
            console.log(`\n- STEP 2 ===================================`);
            // Define the contract's properties
            //const bytecode = fs.readFileSync("./contractBytecode.bin").toString();
            const data = await fetch("./contractBytecode.bin");
            const bytecode = await data.text();
            //console.log(bytecode);
            const abi = [
                "constructor(uint totalSupply)",
                // Read-Only Functions
                "function balanceOf(address owner) view returns (uint256)",
                "function decimals() view returns (uint8)",
                "function symbol() view returns (string)",
                // Authenticated Functions
                "function transfer(address to, uint amount) returns (bool)",
                // Events
                "event Transfer(address indexed from, address indexed to, uint amount)",
            ];
            // Create a ContractFactory object
            const factory = new hethers.ContractFactory(abi, bytecode, wallet);
            // Deploy the contract
            const contract = await factory.deploy(100, { gasLimit: 300000 });
            // Get the address of the deployed contract
            const contractAddress = contract.address;
            console.log(`\n- Contract address: ${contractAddress}`);
            // Wait until the transaction reaches consensus (i.e. contract is deployed)
            //  - returns the receipt
            //  - throws on failure (the reciept is on the error)
            const delay = (ms) => new Promise((res) => setTimeout(res, ms));
            const aa = async () => {
                await delay(11000);
                console.log("Waited 11s");
                const contractDeployWait = await contract.deployTransaction.wait();
                console.log(`\n- Contract deployment status: ${contractDeployWait.status.toString()}`);
                // Transaction sent by the wallet (signer) for deployment - for info
                const contractDeployTx = contract.deployTransaction;
                console.log("contractDeployTx ", contractDeployTx);
                // =============================================================================
                // STEP 3 - INTERACT WITH THE DEPLOYED CONTRACT
                console.log(`\n- STEP 3 ===================================`);
                // Setup a filter and event listener to know when an address receives/sends tokens
                const filter = contract.filters.Transfer(walletAddress, null);
                // @ts-ignore
                contract.once(filter, (from, to, amount, event) => {
                    console.log(`\n- Event: ${from} sent ${amount} tokens to ${to}`);
                });
                // Call contract functions
                const ercSymbol = await contract.symbol({ gasLimit: 300000 });
                console.log(`\n- ERC20 token symbol: ${ercSymbol}`);
                const ercTransfer = await contract.transfer(aliceAddress, 25, {
                    gasLimit: 300000,
                });
                console.log(`\n- Transaction ID for ERC20 transfer: ${ercTransfer.transactionId}`);
                const wBalance = await contract.balanceOf(walletAddress, {
                    gasLimit: 300000,
                });
                const aBalance = await contract.balanceOf(aliceAddress, {
                    gasLimit: 300000,
                });
                console.log(`\n- Wallet ERC20 token (${ercSymbol}) balance: ${wBalance.toString()}`);
                console.log(`\n- Alice's ERC20 token (${ercSymbol}) balance: ${aBalance.toString()}`);
                console.log(`\n- DONE ===================================`);
            };
            aa();
        }
        //test1();
        //test2();
        //test3();
        //test4();
        //test5();
        // randomNumber();
        //timestamp_year(10);
        //genED25519();
        //randomnumberAPI();
        //fileAPI();
        //generateECDSA()
        BRNG?.addEventListener("click", listenerFunction);
        async function listenerFunction() {
            var header = document.getElementById("rng");
            var a = 1;
            var b = 50;
            const str = (await randomNumber(a, b)).toString();
            header.innerText =
                "Random Number between " +
                    a.toString() +
                    " and " +
                    b.toString() +
                    ":\n" +
                    str;
            Get_GEO_Location();
        }
        /*
        var view = new View({
          center: [0, 0],
          zoom: 2,
        })
        const map = new Map({
          layers: [
            new TileLayer({
              source: new OSM(),
            }),
          ],
          target: "map",
          view: view,
        });
    
        
    
        const zo = document.getElementById("zoom-out") as HTMLButtonElement;
          zo.onclick = function() {
            const view = map.getView();
            const zoom = view.getZoom() as number;
            view.setZoom(zoom - 1);
          };
    
          const zi = document.getElementById("zoom-in") as HTMLButtonElement;
          zi.onclick = function() {
            const view = map.getView();
            const zoom = view.getZoom() as number;
            view.setZoom(zoom + 1);
          };
    
    */
        async function Get_GEO_Location() {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            async function success(pos) {
                const crd = await pos.coords;
                //console.log("Your current position is:");
                //console.log(`Latitude : ${crd.latitude}`);
                //console.log(`Longitude: ${crd.longitude}`);
                //console.log(`More or less ${crd.accuracy} meters.`);
                var header = document.getElementById("id3");
                header.innerText = "Your current position is:";
                header = document.getElementById("id4");
                header.innerText = `Latitude : ${crd.latitude}`;
                header = document.getElementById("id5");
                header.innerText = `Longitude: ${crd.longitude}`;
                header = document.getElementById("id6");
                header.innerText = `LocationAccuracy: ${crd.accuracy.toFixed(3)} meters.`;
                if (crd.altitude) {
                    header = document.getElementById("id7");
                    header.innerText = `Altitude: ${crd.altitude.toFixed(3)} meters.`;
                }
                if (crd.altitudeAccuracy) {
                    header = document.getElementById("id8");
                    header.innerText = `AltitudeAccuracy: ${crd.altitudeAccuracy.toFixed(3)} meters.`;
                }
                if (crd.heading) {
                    header = document.getElementById("id9");
                    header.innerText = `Heading: ${crd.heading.toFixed(3)} degrees clockwise from North.`;
                }
                if (crd.speed) {
                    header = document.getElementById("id10");
                    header.innerText = `Speed: ${crd.speed.toFixed(3)} meters per second.`;
                }
                Synchronicity_data.Latitude.push(crd.latitude);
                Synchronicity_data.Longitude.push(crd.longitude);
                Synchronicity_data.Accuracy.push(crd.accuracy.toFixed(3));
                Synchronicity_data.Altitude.push(crd.altitude);
                //Synchronicity_data.AltitudeAccuracy.push(crd.altitudeAccuracy);
                Synchronicity_data.Heading.push(crd.heading);
                Synchronicity_data.Speed.push(crd.speed);
                var GEOdataS = [JSON.stringify(Synchronicity_data)];
                //console.log("appended_json:", GEOdataS);
                var blob = new Blob(GEOdataS, {
                    type: "application/json",
                });
                var link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "synchronicity.json";
                link.innerHTML = " <br> Click here to download <br> <br>";
                link.className = "p1";
                const aa = document.getElementById("Synchronicity_data");
                while (aa.hasChildNodes()) {
                    aa.removeChild(aa.firstChild);
                }
                aa.appendChild(link);
                //const marker1 = new mapboxgl.Marker()
                // .setLngLat([crd.longitude, crd.latitude])
                // .addTo(map);
                // map.setCenter([crd.longitude, crd.latitude]);//[lng, lat]
                var lat = crd.latitude;
                var lng = crd.longitude + Math.random();
                const rn = Synchronicity_data.RandomNumber[Synchronicity_data.RandomNumber.length - 1];
                const ts = Synchronicity_data.TimeStamp[Synchronicity_data.TimeStamp.length - 1];
                const lt = Synchronicity_data.Latitude[Synchronicity_data.Latitude.length - 1];
                const ln = Synchronicity_data.Longitude[Synchronicity_data.Longitude.length - 1];
                const ac = Synchronicity_data.Accuracy[Synchronicity_data.Accuracy.length - 1];
                const jso = {
                    RandomNumber: rn,
                    TimeStamp: ts,
                    Latitude: Number(lt),
                    Longitude: Number(ln),
                    Accuracy: ac,
                    Altitude: crd.altitude,
                    //altitudeAccuracy: crd.altitudeAccuracy,
                    heading: crd.heading,
                    speed: crd.speed,
                };
                L.marker([jso.Latitude, jso.Longitude])
                    .addTo(map)
                    //.bindPopup(JSON.stringify(jso), { maxWidth: 460, maxHeight: 160 })
                    .bindPopup("RandomNumber: " +
                    String(jso.RandomNumber) +
                    "<br>TimeStamp: " +
                    String(jso.TimeStamp) +
                    "<br>Latitude: " +
                    String(jso.Latitude.toFixed(3)) +
                    "<br>Longitude: " +
                    String(jso.Longitude.toFixed(3)) +
                    "<br>Accuracy: " +
                    String(jso.Accuracy +
                        " meters." +
                        '<br><audio controls loop> <source src="./sounds/predator-theme.mp3" type="audio/mpeg"> </audio>'), { maxWidth: 560, maxHeight: 160 })
                    .openPopup();
                //.bindPopup("A pretty CSS3 popup.<br> Easily customizable.")
                //.openPopup();
                //map.panTo([crd.latitude,crd.longitude]);
                map.setView([jso.Latitude, jso.Longitude], 16);
            }
            function error(err) {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }
            navigator.geolocation.getCurrentPosition(success, error, options);
        }
        function beep() {
            snd.play();
        }
        // Add event listener on keydown
        document.addEventListener("keydown", async (event) => {
            var name = event.key;
            var code = event.code;
            // Alert the key name and key code on keydown
            if (name === "1") {
                navigator.mediaDevices
                    .getUserMedia({ video: false, audio: true })
                    .then((stream) => {
                    pause.click();
                    if (mediaRecorder) {
                        if (mediaRecorder.state === "paused") {
                            sndPause.play();
                        }
                    }
                })
                    .catch((err) => {
                    console.error(`you got an error: ${err}`);
                });
                beep();
                BRNG.click();
            }
        }, false);
    },
});
